import React from 'react';
import styled from 'styled-components';

const ExternalPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const ExternalPage = ({ url }) => {
  return (
    <ExternalPageContainer>
      <Iframe src={url} title="External Page" />
    </ExternalPageContainer>
  );
};

export default ExternalPage;