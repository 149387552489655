import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import StatusPage from "./statusPage";
// import React from 'react';
import ExternalPage from './ExternalPage';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <StatusPage /> */}
    <ExternalPage url="https://main29.statuspage.io/" />
  </React.StrictMode>
);

